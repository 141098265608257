import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
        path        : '',
        loadChildren: () => import('./customer-side/customer-side.module').then(m => m.CustomerSideModule),
    },{
        path        : 'backoffice',
        loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule),
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

